// Third-Party
import type { NextPage } from "next";
import Head from "next/head";
import Image from "next/image";
import Router, { useRouter } from "next/router";
// Resources
import styles from "../styles/Home.module.css";

const LandingPage: NextPage = () => {
    // -------------------------------------------------------------------------
    // State
    // -------------------------------------------------------------------------
    const router = useRouter();

    // -------------------------------------------------------------------------
    // Life Cycle
    // -------------------------------------------------------------------------
    // N/A

    // -------------------------------------------------------------------------
    // Rendering
    // -------------------------------------------------------------------------
    const final_render_element = (
        <div className={styles.container}>
            <Head>
                <title>Magic Leap 1 Flashing Tool</title>
                <meta name="description" content="A website for flashing your ML1 Device in the browser." />
                <meta property="og:title" content="Magic Leap 1 Flashing Tool" />
                <meta property="og:description" content="A website for flashing your ML1 Device in the browser." />
                <link rel="icon" href="/favicon.ico" />
            </Head>

            <main
                style={{
                    alignItems: "center",
                    display: "flex",
                    flex: "1",
                    flexDirection: "column",
                    justifyContent: "center",
                    minHeight: "100vh",
                    padding: "4rem 0",
                }}
            >
                <br />

                <h1 className={styles.title}>Magic Leap 1 Device Flasher</h1>

                <p className={styles.description}>Get started by selecting how you would like to flash your device.</p>

                <div className={styles.grid}>
                    {/*
                    <a
                        className={styles.card}
                        onClick={(event: any) => {
                            router.push("step-by-step");
                        }}
                    >
                        <h2>Step-by-Step</h2>
                        <p>Flash each partition yourself.</p>
                    </a>
                    */}
                    <a
                        className={styles.card}
                        onClick={(event: any) => {
                            router.push("one-click");
                        }}
                    >
                        <h2>One-Click</h2>
                        <p>Use our one-click flash approach to flash your entire device.</p>
                    </a>
                </div>
            </main>
        </div>
    );

    return final_render_element;
};

export default LandingPage;
